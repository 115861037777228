<template>
  <router-view></router-view>
</template>

<script>
import store from '@/store';

export default {
  beforeRouteEnter(to, from, next) {
    getPageData(to, next);
  },
  beforeRouteUpdate(to, from, next) {
    getPageData(to, next);
  },
};

async function getPageData(routeTo, next) {
  try {
    await store.dispatch('project/fetchProject', routeTo.params.project);

    next();
  } catch (error) {
    if (error.response && error.response.status == 404) {
      next({ name: '404', params: { resource: 'profile' } });
    } else {
      next({ name: 'network-issue' });
    }
  }
}
</script>
